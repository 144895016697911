import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import React from 'react';
import AboutUs from './AboutUs/index';
import Solutions from './Solutions';
import ContactUs from './ContactUs';

function App() {

  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/contact-us" element={<ContactUs />} />


        
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
  );
}

export default App;
