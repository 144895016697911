
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlidingElement from '../utils/SlidingElement';
import Lifecycle from '../utils/Lifecycle';
const items = [
    { name: 'Client moeving', img: '/images/client-1.png', link: 'https://moeving.com/' },
    { name: 'Client snapecabs', img: '/images/client-2.png', link: 'https://snapecabs.com/' },
    { name: 'Client oredaodisha', img: '/images/client-3.png', link: 'https://oredaodisha.com/' },
    { name: 'Client recindia', img: '/images/client-4.png', link: 'https://recindia.nic.in/' },
    { name: 'Client bluwheelz', img: '/images/client-5.png', link: 'https://bluwheelz.co.in/beta/' },
    { name: 'Client blu-smart', img: '/images/client-6.png', link: 'https://blu-smart.com/en-IN/' },
    { name: 'Client exponent energy', img: '/images/client-7.svg', link: 'https://www.exponent.energy/' },
    { name: 'Client omegaseikimobility', img: '/images/client-8.png', link: 'https://omegaseikimobility.com/' },
    { name: 'Client team magenta', img: '/images/client-9.webp', link: 'https://www.teammagenta.com/' },
    { name: 'Client log9materials', img: '/images/client-10.jpeg', link: 'https://www.log9materials.com/' },
    

    // Add more items as needed
];

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 1500,
    cssEase: "linear",
};




export default function Solutions() {


    const [currentTab, setCurrentTab] = useState(0)

    useEffect(() => {
        const hash = window.location.hash;

        if (hash) {
            const id = hash.substring(1); // Remove the `#`
            const numberMatch = id.match(/\d+/); // Match the number in the string
            if (numberMatch) {
                setCurrentTab(Number(numberMatch[0])); // Set the first matched number
            }
        }
    }, []);

    return (
        <main className="overflow-hidden">
            <div className="relative 2xl:h-[760px] xl:h-[634px] lg:h-[506px] md:h-[380px] sm:h-[316px] overflow-hidden">
                <SlidingElement direction="left">
                    <img className="sm:block hidden" src="/images/solutions-hero.webp" />
                </SlidingElement>
                <img className="sm:hidden block w-full" src="/images/home-mobile-hero.webp" />
                {/* <div className='absolute flex justify-center sm:bottom-[40px] bottom-[150px] w-full'>
                    <ChevronDownIcon className='animate-float sm:w-[100px] w-[40px]' color='#cccbcb' />
                </div> */}
                <div className="sm:absolute top-0 right-[10%] bottom-0 m-auto h-fit">
                    <SlidingElement direction="right">

                        <h3 className="font-virtue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl sm:p-0 p-4 tracking-tighter text-blue ">
                            One-stop-shop<br />
                            for all new age<br />
                            mobility leasing<br />
                            solutions
                        </h3>
                    </SlidingElement>
                </div>
            </div>

            <div className="text-center sm:py-10 py-4 relative bg-gray-100">
                <h3 className="font-virtue text-blue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl mb-4 tracking-tighter">
                    Trusted By
                </h3>
                <Slider {...settings}>
                    {items.map((item, index) => (
                        <div key={index} className="inline-block">
                            <a target="_blank" rel="noopener noreferrer" href={item.link}>
                                <img className={`sm:w-[144px] w-[36px]`} src={item.img} alt={item.name} />
                            </a>
                        </div>
                    ))}
                </Slider>
            </div>


            <div className='sm:py-10 py-4 relative sm:px-20 px-4'>
                <div className="flex sm:gap-6 gap-2">
                    <SlidingElement direction="left">

                        <div className="flex flex-col">
                            <div className="h-0" id="our-offerings-0"></div>
                            <div className="h-0" id="our-offerings-1"></div>
                            <div className="h-0" id="our-offerings-2"></div>
                            <div className="h-0" id="our-offerings-3"></div>
                            <div className="h-0" id="our-offerings-4"></div>
                            <div className="h-0" id="our-offerings-5"></div>
                            <div className="sm:w-[500px] flex sm:gap-6 gap-2 sm:pb-10 pb-4 border-b-[1px] border-black">
                                <div onClick={() => setCurrentTab(0)} className="flex-none cursor-pointer">
                                    <img className="sm:w-[54px] w-[20px] sm:mt-0 mt-1" src="/images/money-dark-icon.png" />
                                </div>
                                <div onClick={() => setCurrentTab(0)} className='cursor-pointer'>
                                    <h3 className="text-base sm:text-lg md:text-lg xl:text-3xl text-blue tracking-tighter font-virtue">Budgeted Lease Cost</h3>
                                    {currentTab == 0 && <p className="font-poppins text-sm sm:text-base md:text-lg mt-4">Capped expenses for the entire life cycle of the EV to assure that leasing delivers the maximum for your organization while meeting your specific businesses needs.
                                    </p>}
                                </div>
                            </div>
                            <div className="sm:w-[500px] flex sm:gap-6 gap-2 sm:py-7 py-3 border-b-[1px] border-black">
                                <div onClick={() => setCurrentTab(1)} className="flex-none cursor-pointer">
                                    <img className="sm:w-[54px] w-[20px] sm:mt-0 mt-1" src="/images/network-icon.png" />
                                </div>
                                <div onClick={() => setCurrentTab(1)} className='cursor-pointer'>
                                    <h3 className="text-base sm:text-lg md:text-lg xl:text-3xl text-blue tracking-tighter font-virtue">One-Stop Solution</h3>
                                    {currentTab == 1 && <p className="font-poppins text-sm sm:text-base md:text-lg mt-4">One-stop EV leasing solution for businesses and individuals.

                                    </p>}
                                </div>
                            </div>
                            <div className="sm:w-[500px] flex sm:gap-6 gap-2 sm:py-7 py-3 border-b-[1px] border-black">
                                <div onClick={() => setCurrentTab(2)} className="flex-none cursor-pointer">
                                    <img className="sm:w-[54px] w-[20px] sm:mt-0 mt-1" src="/images/graph-icon.png" />
                                </div>
                                <div onClick={() => setCurrentTab(2)} className='cursor-pointer'>
                                    <h3 className="text-base sm:text-lg md:text-lg xl:text-3xl text-blue tracking-tighter font-virtue">Accelerated EV Adoption</h3>
                                    {currentTab == 2 && <p className="font-poppins text-sm sm:text-base md:text-lg mt-4">Make EVs accessible and affordable for all.
                                    </p>}
                                </div>
                            </div>
                            <div className="sm:w-[500px] flex sm:gap-6 gap-2 sm:py-7 py-3 border-b-[1px] border-black">
                                <div onClick={() => setCurrentTab(3)} className="flex-none cursor-pointer">
                                    <img className="sm:w-[54px] w-[20px] sm:mt-0 mt-1" src="/images/jigsaw-icon.png" />
                                </div>
                                <div onClick={() => setCurrentTab(3)} className='cursor-pointer'>
                                    <h3 className="text-base sm:text-lg md:text-lg xl:text-3xl text-blue tracking-tighter font-virtue">Flexible Leasing Solution</h3>
                                    {currentTab == 3 && <p className="font-poppins text-sm sm:text-base md:text-lg mt-4">Customised solutions for various use cases.
                                    </p>}
                                </div>
                            </div>
                            <div className="sm:w-[500px] flex sm:gap-6 gap-2 sm:py-7 py-3 border-b-[1px] border-black">
                                <div onClick={() => setCurrentTab(4)} className="flex-none cursor-pointer">
                                    <img className="sm:w-[54px] w-[20px] sm:mt-0 mt-1" src="/images/hand-shake-icon.png" />
                                </div>
                                <div onClick={() => setCurrentTab(4)} className='cursor-pointer'>
                                    <h3 className="text-base sm:text-lg md:text-lg xl:text-3xl text-blue tracking-tighter font-virtue">Leasing Experts</h3>
                                    {currentTab == 4 && <p className="font-poppins text-sm sm:text-base md:text-lg mt-4">Best talent in the industry to innovate and customise leasing solutions.
                                    </p>}
                                </div>
                            </div>
                            <div className="sm:w-[500px] flex sm:gap-6 gap-2 sm:py-7 py-3 border-b-[1px] border-black">
                                <div onClick={() => setCurrentTab(5)} className="flex-none cursor-pointer">
                                    <img className="sm:w-[54px] w-[20px] sm:mt-0 mt-1" src="/images/gear-icon.png" />
                                </div>
                                <div onClick={() => setCurrentTab(5)} className='cursor-pointer'>
                                    <h3 className="text-base sm:text-lg md:text-lg xl:text-3xl text-blue tracking-tighter font-virtue">Experience & Infrastructure</h3>
                                    {currentTab == 5 && <p className="font-poppins text-sm sm:text-base md:text-lg mt-4">Experience of life cycle management of over 225 Million Kms with 7000+ BluSmart fleet.
                                    </p>}
                                </div>
                            </div>
                        </div>
                    </SlidingElement>
                    <div className='sm:block hidden'>
                        <img width={1600} src="/images/e-charger.webp" />
                    </div>
                </div>
            </div>

            <div className='text-center sm:py-10 py-4 relative bg-gray-100'>
                <h3 className='font-virtue text-blue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl mb-4 tracking-tighter'>
                    Assets Offered
                </h3>

                <div className="flex justify-center sm:mt-16 mt-8">
                    <div className="grid grid-cols-6 sm:gap-10 gap-2">
                        <div className="sm:col-span-2 col-span-3">
                            <div className="flex items-center sm:gap-8 gap-2">
                                <div>
                                    <img src="/images/asset-1.png" className="sm:w-[150px] w-[120px]" />
                                </div>
                                <div className="font-gothamBold text-left sm:text-2xl text-sm text-light-blue leading-6">
                                    Two<br />Wheeler
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2 col-span-3">
                            <div className="flex items-center sm:gap-4 gap-2">
                                <div>
                                    <img src="/images/asset-2.png" className="sm:w-[150px] w-[120px]" />
                                </div>
                                <div className="font-gothamBold text-left sm:text-2xl text-sm text-light-blue leading-6">
                                    Four<br />
                                    Wheeler<br />
                                    {/* Vehicle<br /> */}
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2 col-span-3">
                            <div className="flex items-center sm:gap-6 gap-2">
                                <div>
                                    <img src="/images/asset-3.png" className="sm:w-[150px] w-[120px]" />
                                </div>
                                <div className="font-gothamBold text-left sm:text-2xl text-sm text-light-blue leading-6">
                                    Buses
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2 col-span-3">
                            <div className="flex items-center sm:gap-8 gap-2">
                                <div>
                                    <img src="/images/asset-4.png" className="sm:w-[150px] w-[120px]" />
                                </div>
                                <div className="font-gothamBold text-left sm:text-2xl text-sm text-light-blue leading-6">
                                    Three<br />
                                    Wheeler<br />
                                    Cargo
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2 col-span-3">
                            <div className="flex items-center sm:gap-4 gap-2">
                                <div>
                                    <img src="/images/asset-5.png" className="sm:w-[150px] w-[120px]" />
                                </div>
                                <div className="font-gothamBold text-left sm:text-2xl text-sm text-light-blue leading-6">
                                    Light<br />
                                    Commercial<br />
                                    Wheeler &<br />
                                    Passenger<br />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2 col-span-3">
                            <div className="flex items-center sm:gap-4 gap-2">
                                <div>
                                    <img src="/images/asset-6.png" className="sm:w-[150px] w-[120px]" />
                                </div>
                                <div className="font-gothamBold text-left sm:text-2xl text-sm text-light-blue leading-6">
                                    Heavy<br />
                                    Commercial<br />
                                    Vehicle
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Lifecycle />

            <div className='text-center sm:py-10 py-4 relative'>
                <h3 className='font-virtue text-blue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl mb-4 tracking-tighter'>
                    Leasing Solutions for Every Need
                </h3>


                <div className="flex justify-center text-center">
                    <p className="font-poppins text-sm sm:text-base md:text-lg sm:mt-4 mt-2">
                        Let’sEV is a new age solutions partner of new age mobility for individuals,<br />
                        corporates and government bodies across various form factors of EVs.
                    </p>
                </div>
                <div className="flex justify-center sm:mt-16 mt-4 sm:px-20 px-4">
                    <div className="grid grid-cols-6 sm:gap-10 gap-2">
                        <div className="sm:col-span-2 col-span-6">
                            <div className="flex items-center sm:gap-2 gap-4">
                                <div>
                                    <img src="/images/solution-1.webp" className="sm:w-[100px] w-[35px]" />
                                </div>
                                <div className="">
                                    <h3 className='font-gothamBold text-left sm:text-lg text-sm text-light-blue leading-6'>
                                        Large MNCs & Indian<br /> Business Houses
                                    </h3>
                                    <p className='font-poppins sm:text-base text-xs text-left'>
                                        Perk, Salary Sacrifice, Pool Vehicles
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2 col-span-6">
                            <div className="flex items-center sm:gap-2 gap-4">
                                <div>
                                    <img src="/images/solution-2.webp" className="sm:w-[100px] w-[35px]" />
                                </div>
                                <div className="">
                                    <h3 className='font-gothamBold text-left sm:text-lg text-sm text-light-blue leading-6'>
                                        Fleet Operators
                                    </h3>
                                    <p className='font-poppins sm:text-base text-xs text-left'>
                                        Ride hailing, Employee Transportation,<br /> Rent A Car
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2 col-span-6">
                            <div className="flex items-center sm:gap-2 gap-4">
                                <div>
                                    <img src="/images/solution-3.webp" className="sm:w-[100px] w-[35px]" />
                                </div>
                                <div className="">
                                    <h3 className='font-gothamBold text-left sm:text-lg text-sm text-light-blue leading-6'>
                                        Logistics Companies<br />/E-commerce
                                    </h3>
                                    <p className='font-poppins sm:text-base text-xs text-left'>
                                        Last mile delivery, mid mile delivery
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2 col-span-6">
                            <div className="flex items-center sm:gap-2 gap-4">
                                <div>
                                    <img src="/images/solution-4.webp" className="sm:w-[100px] w-[35px]" />
                                </div>
                                <div className="">
                                    <h3 className='font-gothamBold text-left sm:text-lg text-sm text-light-blue leading-6'>
                                        PSU & Government<br />Departments
                                    </h3>
                                    <p className='font-poppins sm:text-base text-xs text-left'>
                                        Perk, Salary Sacrifice, Pool Vehicles
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2 col-span-6">
                            <div className="flex items-center sm:gap-2 gap-4">
                                <div>
                                    <img src="/images/solution-5.webp" className="sm:w-[100px] w-[35px]" />
                                </div>
                                <div className="">
                                    <h3 className='font-gothamBold text-left sm:text-lg text-sm text-light-blue leading-6'>
                                        Retail Subscription
                                    </h3>
                                    <p className='font-poppins sm:text-base text-xs text-left'>
                                        Perk, Salary Sacrifice, Pool Vehicles
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2 col-span-6">
                            <div className="flex items-center sm:gap-2 gap-4">
                                <div>
                                    <img src="/images/solution-6.webp" className="sm:w-[100px] w-[35px]" />
                                </div>
                                <div className="">
                                    <h3 className='font-gothamBold text-left sm:text-lg text-sm text-light-blue leading-6'>
                                        Close Circuit Usage
                                    </h3>
                                    <p className='font-poppins sm:text-base text-xs text-left'>
                                        Airports, Office- college campuses
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='sm:py-10 py-4 lg:px-20 md:px-10 px-4 relative bg-gray-100'>
                <div className='flex sm:justify-between sm:flex-row flex-col lg:gap-4 md:gap-4 gap-1'>
                    <div>
                        <h3 className='font-virtue text-blue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl mb-4 tracking-tighter'>
                            Ready to be a part of the EV revolution?
                        </h3>
                        <p className='font-poppins mt-4'>
                            You’re just one click away from being a part of the EV revolution & transforming your<br /> organisation. Let’s collaborate for positive change, Let’sEV.
                        </p>
                        <p className='font-poppins mt-4'>
                            Reach out to us an get in touch with an expert.
                        </p>
                    </div>
                    <div className='flex sm:justify-end justify-start w-fit'>
                        <a href="/contact-us">
                            <button className="bg-blue hover:bg-light-blue transition duration-300 text-white text-sm sm:text-base md:text-lg font-poppins sm:mt-4 mt-1 px-12 py-3 rounded-full">
                                Contact Us
                            </button>
                        </a>
                    </div>

                </div>

            </div>


        </main>
    )
}