
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

function SlidingElement({ direction = 'left', duration = 0.6, children }:any) {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const variants = {
        hidden: {
            x: direction === 'left' ? -100 : 100,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                duration: duration,
                ease: 'easeOut',
            },
        },
    };

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            variants={variants}
        >
            {children}
        </motion.div>
    );
}

export default SlidingElement;
