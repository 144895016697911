'use client'
import { useState } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import SlidingElement from "../utils/SlidingElement";

export default function ContactUs() {
    const [form, setForm] = useState({
        name: "",
        email: "",
        phone: "",
        company: "",
        message: ""
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        company: ""
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false)

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });

        setSuccessMessage("");
    };

    const validate = () => {
        const newErrors = {
            name: "",
            email: "",
            phone: "",
            company: ""
        };
        let isValid = true;

        if (!form.name) {
            newErrors.name = "Name is required";
            isValid = false;
        }
        if (!form.email) {
            newErrors.email = "Email is required";
            isValid = false;
        }
        if (!form.phone) {
            newErrors.phone = "Phone number is required";
            isValid = false;
        }
        if (!form.company) {
            newErrors.company = "Company is required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();


        const url = "https://script.google.com/macros/s/AKfycbw4ENvDdmAVAaiyHRp3PV_TXVIA_nTI7Aog9DrjQi3YLKlLNMbTsjbN_y0nMlQ_7fwoBg/exec"
        
        setLoading(true)
        if (!validate()) {
            return;
        }

        // if (!form.message) {
        //     alert("Message is required");
        //     return;
        // }

        try {
            await addDoc(collection(db, "leads"), form);
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    mode: 'no-cors', // no-cors mode is used here because the web app doesn't return any content
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(form)
                });

                if (response.ok) {
                    console.log('Data successfully sent to Google Sheets');
                } else {
                    console.error('Error sending data to Google Sheets');
                }
            } catch (error) {
                console.error('Error:', error);
            }
            setSuccessMessage("Details submitted successfully");
            setForm({
                name: "",
                email: "",
                phone: "",
                company: "",
                message: ""
            });
            setLoading(false)
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };
    return (
        <main className="overflow-hidden">
            <div className="relative 2xl:h-[760px] xl:h-[634px] lg:h-[506px] md:h-[380px] sm:h-[316px] overflow-hidden">

                <SlidingElement direction="left">
                    <img
                        src="/images/contact-hero.webp"
                        className="hidden sm:block w-full"
                        alt="Desktop Hero"
                        style={{ width: "100vw" }}
                    />
                </SlidingElement>
                <img className="block sm:hidden w-full" src="/images/contact-us-mobile-hero.webp" />
                {/* <div className='absolute flex justify-center sm:bottom-[40px] bottom-[150px] w-full'>
                    <ChevronDownIcon className='animate-float sm:w-[100px] w-[40px]' color='#cccbcb' />
                </div> */}
                <div className="sm:absolute top-0 right-[8%] bottom-0 m-auto h-fit">
                    <SlidingElement direction="right">
                        <h3 className="font-virtue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl sm:mt-0 mt-4 sm:px-0 px-4 tracking-tighter text-blue ">
                            Get In Touch<br />
                            With Our Team<br />
                            of Experts<br />
                        </h3>
                    </SlidingElement>
                </div>
            </div>

            <div className="grid grid-cols-2 sm:px-20 px-4 sm:py-20 py-10 sm:gap-0 gap-10">
                <div className="sm:col-span-1 col-span-2 flex flex-col">
                    {/* <div className="flex items-center sm:mb-5 mb-1 gap-3">
                        <img width={30} src="/images/phone-dark-icon.png"></img>
                        <p className="font-poppins sm:text-xl text-sm">+91 1234567890</p>
                    </div> */}
                    <div className="flex items-center sm:mb-5 mb-1 gap-3">
                        <img width={30} src="/images/mail-dark-icon.png"></img>
                        <p className="font-poppins sm:text-xl text-sm">communications@letsevleasing.com</p>
                    </div>
                    <div className="flex items-center sm:mb-5 mb-1 gap-3">
                        <img width={30} src="/images/location-dark-icon.png"></img>
                        <p className="font-poppins sm:text-xl text-sm">India</p>
                    </div>
                    <p className="font-poppins sm:text-xl text-sm sm:mb-9 mb-2">
                        6th Floor, Capital Cyberscape, Dayma Road,<br />
                        Ullahwas, Sector 59, Gurugram, Haryana - 122102
                    </p>
                    {/* <p className="font-poppins sm:text-xl text-sm sm:mb-5 mb-1">
                        Head Office: Ahmedabad Block - A, 15th Floor,
                        Westgate Business Bay, Makarba, Ahmedabad
                        - 380051, Gujarat, India.
                    </p>
                    <div className="flex items-center sm:mb-5 mb-1 gap-3">
                        <img width={30} src="/images/location-dark-icon.png"></img>
                        <p className="font-poppins sm:text-xl text-sm">Dubai</p>
                    </div>
                    <p className="font-poppins sm:text-xl text-sm sm:mb-5 mb-1">
                        Office: UAE 6A, Standard Chartered Tower, Emaar
                        Square, Downtown, Dubai Phone: +971 832 2320
                    </p> */}
                    <div className="mt-5 flex gap-2">
                        {/* <div>
                            <img width={30} src="/images/facebook-dark-icon.png" />
                        </div> */}
                        <div>
                            <a target="_blank" href="https://www.instagram.com/letsevleasing/">
                                <img width={30} src="/images/instagram-dark-icon.png" />
                            </a>
                        </div>
                        <div>
                            <a target="_blank" href="https://www.linkedin.com/company/letsevleasing/">
                                <img width={30} src="/images/linkedin-dark-icon.png" />
                            </a>
                        </div>
                    </div>

                </div>
                <div className="sm:col-span-1 col-span-2">
                    <SlidingElement direction="right">

                        <form onSubmit={handleSubmit}>
                            <div className="mb-5">
                                <input
                                    className={`border-[1px] w-full text-sm sm:text-base md:text-lg font-poppins sm:px-8 px-4 sm:py-4 py-2 rounded-full ${errors.name ? 'border-red-500' : 'border-black'}`}
                                    placeholder="NAME"
                                    type="text"
                                    name="name"
                                    value={form.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <p className="text-xs text-red-500 ml-4 font-poppins italic">{errors.name}</p>}
                            </div>
                            <div className="mb-5">
                                <input
                                    className={`border-[1px] w-full text-sm sm:text-base md:text-lg font-poppins sm:px-8 px-4 sm:py-4 py-2 rounded-full ${errors.email ? 'border-red-500' : 'border-black'}`}
                                    placeholder="EMAIL"
                                    type="email"
                                    name="email"
                                    value={form.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <p className="text-xs text-red-500 ml-4 font-poppins italic">{errors.email}</p>}
                            </div>
                            <div className="mb-5">
                                <input
                                    className={`border-[1px] w-full text-sm sm:text-base md:text-lg font-poppins sm:px-8 px-4 sm:py-4 py-2 rounded-full ${errors.phone ? 'border-red-500' : 'border-black'}`}
                                    placeholder="PHONE NUMBER"
                                    type="tel"
                                    name="phone"
                                    value={form.phone}
                                    onChange={handleChange}
                                />
                                {errors.phone && <p className="text-xs text-red-500 ml-4 font-poppins italic">{errors.phone}</p>}
                            </div>
                            <div className="mb-5">
                                <input
                                    className={`border-[1px] w-full text-sm sm:text-base md:text-lg font-poppins sm:px-8 px-4 sm:py-4 py-2 rounded-full ${errors.company ? 'border-red-500' : 'border-black'}`}
                                    placeholder="COMPANY"
                                    type="text"
                                    name="company"
                                    value={form.company}
                                    onChange={handleChange}
                                />
                                {errors.company && <p className="text-xs text-red-500 ml-4 font-poppins italic">{errors.company}</p>}
                            </div>
                            <div className="mb-5">
                                <textarea
                                    rows={7}
                                    className="border-black border-[1px] w-full text-sm sm:text-base md:text-lg font-poppins sm:px-8 px-4 sm:py-4 py-2 sm:rounded-[47px] rounded-[20px]"
                                    placeholder="MESSAGE"
                                    name="message"
                                    value={form.message}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                            <button className="bg-blue flex relative hover:bg-light-blue transition duration-300 text-white text-sm sm:text-base md:text-lg font-poppins px-16 py-3 rounded-full">
                                {loading && <div className="animate-spin loader absolute right-6"></div>}
                                Submit
                            </button>
                            {successMessage && <p className="text-sm text-green-500 mt-3 font-poppins italic">{successMessage}</p>}
                        </form>

                    </SlidingElement>
                </div>
            </div>

            <div className="py-10 relative bg-gray-100 text-center flex flex-col items-center">
                <h3 className="font-virtue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl tracking-tighter text-blue">
                    Reach Our Offices
                </h3>

                <div className="px-32 flex gap-12 font-poppins text-sm sm:text-base md:text-lg mt-7">
                    <div className="border-b-[2px] border-black text-center pb-2 leading-3 font-poppinsBold">Gurugram</div>
                    {/* <div className="border-b-[1px] border-black text-center pb-2 leading-3">Ahmedabad</div>
                    <div className="border-b-[1px] border-black text-center pb-2 leading-3">Dubai</div> */}
                </div>

                <div className="w-full flex justify-center mt-7 mb-10">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.9661035788642!2d77.1012155958865!3d28.401082126781002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d218a16b4f9cd%3A0xbb3f6ee2ccec9689!2sCapital%20Cyberscape!5e0!3m2!1sen!2sin!4v1722501395470!5m2!1sen!2sin"
                        width="90%"
                        height="600"
                        style={{ border: 0, borderRadius: 60, boxShadow: "0px 16px #00AAE3" }}
                        allowFullScreen={true}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>

            </div>


        </main>
    )
}