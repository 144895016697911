import SlidingElement from "../utils/SlidingElement";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

export default function Lifecycle() {
    const images = [
        '/images/cycle-management.webp',// End of Term 
        '/images/cycle-management-3.webp',// Services
        '/images/cycle-management.webp', //  Vehicle Acquisition & Leasing
        '/images/cycle-management-1.webp', // Insurance Management
        '/images/cycle-management-2.webp',// Maintenance Management*
        '/images/cycle-management-3.webp',// Accidents/Damage & Claims Handling
        '/images/cycle-management-4.webp',// Breakdown Assistance
        '/images/cycle-management-2.webp',// Value Added Services*
        '/images/cycle-management-6.webp',// Charging Infrastructure*

    ];

    const [currentIndex, setCurrentIndex] = useState(0); // Start with the third image centered

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const getRotatedImages = () => {
        const rotated = [...images];
        return rotated.slice(currentIndex).concat(rotated.slice(0, currentIndex));
    };

    return (
        <div className='text-center sm:my-20 my-16 relative'>
            <div className="absolute sm:-top-24 -top-16 right-0">
                <SlidingElement direction="right">
                    <img src="/images/decorator.png" className='rotate-180 sm:w-[170px] w-[50px]' />
                </SlidingElement>
            </div>
            <h3 className='font-virtue text-blue text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl sm:mb-20 mb-8 tracking-tighter sm:px-0 px-2'>
                Complete Life Cycle Management of EV
            </h3>
            { }
            <div className="carousel-container">
                <button className="carousel-btn prev" onClick={handlePrev}><ChevronDoubleLeftIcon aria-hidden="true" className="block h-10 w-10" /></button>
                <div className="carousel">
                    {getRotatedImages().map((image, index) => (
                        index < 5 && <div
                            key={index}
                            className={`carousel-item ${index === 2 ? 'carousel-item-center' : 'carousel-item-side'}`}
                        >
                            <img src={image} alt={`Slide ${index + 1}`} />
                        </div>
                    ))}
                </div>
                <button className="carousel-btn next" onClick={handleNext}><ChevronDoubleRightIcon aria-hidden="true" className="block h-10 w-10" /></button>
            </div>


            {currentIndex == 5 &&

                <SlidingElement direction="right">
                    <div className='flex flex-row justify-center items-stretch mt-16'>
                        <div className='border-r-[1px] border-b-[0px] sm:border-l-[1px] border-l-[0px] border-blue sm:px-8 px-2 flex flex-col justify-center'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-blue'>STEP 5</h3>
                        </div>
                        <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-light-blue'>Value Added<br/>Services</h3>
                        </div>
                        {/* <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppins text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-blue'>
                                Lorem Ipsum is simply<br />
                                dummy text of the printing<br />
                                and typesetting industry.<br />
                            </h3>
                        </div> */}
                    </div>
                </SlidingElement>
            }
            {currentIndex == 6 &&

                <SlidingElement direction="right">
                    <div className='flex flex-row justify-center items-stretch mt-16'>
                        <div className='border-r-[1px] border-b-[0px] sm:border-l-[1px] border-l-[0px] border-blue sm:px-8 px-2 flex flex-col justify-center'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-blue'>STEP 6</h3>
                        </div>
                        <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-light-blue'>Charging<br/>Infrastructure</h3>
                        </div>
                        {/* <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppins text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-blue'>
                                Lorem Ipsum is simply<br />
                                dummy text of the printing<br />
                                and typesetting industry.<br />
                            </h3>
                        </div> */}
                    </div>
                </SlidingElement>
            }
            {currentIndex == 7 &&

                <SlidingElement direction="right">
                    <div className='flex flex-row justify-center items-stretch mt-16'>
                        <div className='border-r-[1px] border-b-[0px] sm:border-l-[1px] border-l-[0px] border-blue sm:px-8 px-2 flex flex-col justify-center'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-blue'>STEP 7</h3>
                        </div>
                        <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-light-blue'>End of<br/> Term</h3>
                        </div>
                        {/* <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppins text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-blue'>
                                Lorem Ipsum is simply<br />
                                dummy text of the printing<br />
                                and typesetting industry.<br />
                            </h3>
                        </div> */}
                    </div>
                </SlidingElement>
            }
            {currentIndex == 8 &&

                <SlidingElement direction="right">
                    <div className='flex flex-row justify-center items-stretch mt-16'>
                        <div className='border-r-[1px] border-b-[0px] sm:border-l-[1px] border-l-[0px] border-blue sm:px-8 px-2 flex flex-col justify-center'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-blue'>STEP 8</h3>
                        </div>
                        <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-light-blue'>Services</h3>
                        </div>
                        {/* <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppins text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-blue'>
                                Lorem Ipsum is simply<br />
                                dummy text of the printing<br />
                                and typesetting industry.<br />
                            </h3>
                        </div> */}
                    </div>
                </SlidingElement>
            }
            {currentIndex == 0 &&

                <SlidingElement direction="right">
                    <div className='flex flex-row justify-center items-stretch mt-16'>
                        <div className='border-r-[1px] border-b-[0px] sm:border-l-[1px] border-l-[0px] border-blue sm:px-8 px-2 flex flex-col justify-center'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-blue'>STEP 1</h3>
                        </div>
                        <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-light-blue'>Vehicle Acquisition<br/>& Leasing</h3>
                        </div>
                        {/* <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppins text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-blue'>
                                Lorem Ipsum is simply<br />
                                dummy text of the printing<br />
                                and typesetting industry.<br />
                            </h3>
                        </div> */}
                    </div>
                </SlidingElement>
            }
            {currentIndex == 1 &&

                <SlidingElement direction="right">
                    <div className='flex flex-row justify-center items-stretch mt-16'>
                        <div className='border-r-[1px] border-b-[0px] sm:border-l-[1px] border-l-[0px] border-blue sm:px-8 px-2 flex flex-col justify-center'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-blue'>STEP 2</h3>
                        </div>
                        <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-light-blue'>Insurance<br/>Management</h3>
                        </div>
                        {/* <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
            <h3 className='font-poppins text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-blue'>
                Lorem Ipsum is simply<br />
                dummy text of the printing<br />
                and typesetting industry.<br />
            </h3>
        </div> */}
                    </div>
                </SlidingElement>
            }
            {currentIndex == 2 &&

                <SlidingElement direction="right">
                    <div className='flex flex-row justify-center items-stretch mt-16'>
                        <div className='border-r-[1px] border-b-[0px] sm:border-l-[1px] border-l-[0px] border-blue sm:px-8 px-2 flex flex-col justify-center'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-blue'>STEP 3</h3>
                        </div>
                        <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-light-blue'>Maintenance<br/>Management</h3>
                        </div>
                        {/* <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                        <h3 className='font-poppins text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-blue'>
                        Lorem Ipsum is simply<br />
                        dummy text of the printing<br />
                        and typesetting industry.<br />
                        </h3>
                        </div> */}
                    </div>
                </SlidingElement>
            }
            {currentIndex == 3 &&

                <SlidingElement direction="right">
                    <div className='flex flex-row justify-center items-stretch mt-16'>
                        <div className='border-r-[1px] border-b-[0px] sm:border-l-[1px] border-l-[0px] border-blue sm:px-8 px-2 flex flex-col justify-center'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-blue'>STEP 4</h3>
                        </div>
                        <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-light-blue'>Accidents/Damage<br/>& Claims Handling</h3>
                        </div>
                        {/* <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                        <h3 className='font-poppins text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-blue'>
                        Lorem Ipsum is simply<br />
                        dummy text of the printing<br />
                        and typesetting industry.<br />
                        </h3>
                        </div> */}
                    </div>
                </SlidingElement>
            }
            {currentIndex == 4 &&

                <SlidingElement direction="right">
                    <div className='flex flex-row justify-center items-stretch mt-16'>
                        <div className='border-r-[1px] border-b-[0px] sm:border-l-[1px] border-l-[0px] border-blue sm:px-8 px-2 flex flex-col justify-center'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-blue'>STEP 5</h3>
                        </div>
                        <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                            <h3 className='font-poppinsBold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-light-blue'>Breakdown<br />Assistance</h3>
                        </div>
                        {/* <div className='border-r-[1px] border-b-[0px] border-blue sm:px-8 px-2'>
                        <h3 className='font-poppins text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-blue'>
                        Lorem Ipsum is simply<br />
                        dummy text of the printing<br />
                        and typesetting industry.<br />
                        </h3>
                        </div> */}
                    </div>
                </SlidingElement>
            }
        </div>
    )
}