
import SlidingElement from "../utils/SlidingElement"
import { ChartBarIcon, RocketLaunchIcon, ScaleIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import CountUp from "react-countup"

export default function AboutUs() {

    const [currentTab, setCurrentTab] = useState(0)

    return <>
        <main className="overflow-hidden">
            <div className="relative 2xl:h-[760px] xl:h-[634px] lg:h-[506px] md:h-[380px] sm:h-[316px] overflow-hidden">
                <SlidingElement direction="left">
                    <img
                        className="hidden sm:block w-full"
                        alt="Desktop Hero" src="/images/about-us-hero.webp" />
                </SlidingElement>
                <img
                    className="sm:hidden block w-full"
                    src="/images/about-us-mobile-hero.webp" />
                {/* <div className='absolute flex justify-center sm:bottom-[40px] bottom-4 w-full'>
                    <ChevronDownIcon className='animate-float sm:w-[100px] w-[40px]' color='#cccbcb' />
                </div> */}
                <div className="sm:absolute lg:top-[30%] top-[25%] lg:right-[10%] sm:right-[10%] right-[20%] m-auto h-fit">
                    <SlidingElement direction="right">

                        <h3 className="sm:block hidden font-virtue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl tracking-tighter text-blue sm:px-0 px-4">
                            Energising<br />
                            the Future<br />
                            of Mobility<br />

                        </h3>
                    </SlidingElement>
                </div>
            </div>

            <div id="who-we-are" className="flex justify-center">

                <div className="text-center sm:py-10 py-4 sm:w-[80%] w-[90%]">
                    <h3 className="font-virtue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl tracking-tighter text-blue ">
                        Who We Are
                    </h3>
                    <SlidingElement direction="right">

                        <p className="font-poppins text-sm sm:text-base md:text-lg sm:mt-6 mt-2">
                            Let’sEV (Gensol EV Lease Pvt. Ltd.), a subsidiary of Gensol Engineering Limited, is India’s fastest growing EV
                            focused leasing company with a vision to offer sustainable and environmentally conscious mobility solutions at
                            scale. Let’sEV is committed to accelerating the adoption of EVs and through innovative EV Leasing and Fleet
                            Management solutions.
                        </p>

                        <p className="font-poppins text-sm sm:text-base md:text-lg sm:mt-6 mt-2">
                            Founded by pioneers in EV Mobility, Let’sEV is a manifestation of our ambition to make electric mobility
                            accessible to everyone. The team behind Let’sEV brings an in-depth understanding of the leasing value chain
                            and simplified solutions for a diverse set of stakeholders.
                        </p>

                        <p className="font-poppins text-sm sm:text-base md:text-lg sm:mt-6 mt-2">
                            As India strives to achieve its net-zero emissions target by 2070, Let’sEV is at the forefront, contributing to this
                            national mission by promoting green mobility solutions. With the country's rapid growth and urbanization,
                            electric mobility is poised to play a pivotal role in reducing carbon footprints and fostering a sustainable future.
                        </p>

                    </SlidingElement>

                    <div className="flex sm:flex-row flex-col justify-center sm:gap-16 gap-4 sm:py-20 py-10">
                        <div className=" text-left sm:w-[240px]">
                            <h3 className="font-virtue sm:text-6xl text-2xl text-blue tracking-tighter"><CountUp end={5000} duration={3} />+</h3>
                            <h3 className="font-virtue text-base sm:text-lg md:text-xl lg:text-2xl text-blue tracking-tighter">EVs Leased</h3>
                            <h3 className="font-poppins text-sm sm:text-base md:text-lg">Data & experience from
                                managing 5,000+ EVs</h3>

                        </div>
                        <div className=" text-left sm:w-[240px]">
                            <h3 className="font-virtue sm:text-6xl text-2xl text-blue tracking-tighter"><CountUp end={225} duration={3} />M+</h3>
                            <h3 className="font-virtue text-base sm:text-lg md:text-xl lg:text-2xl text-blue tracking-tighter">clean kms</h3>
                            <h3 className="font-poppins text-sm sm:text-base md:text-lg">Clean kilometers
                                travelled & counting</h3>

                        </div>
                        <div className=" text-left w-[310px]">
                            <h3 className="font-virtue sm:text-6xl text-2xl text-blue tracking-tighter"><CountUp end={4500} duration={3} />+</h3>
                            <h3 className="font-virtue text-base sm:text-lg md:text-xl lg:text-2xl text-blue tracking-tighter">EV Charging Stations</h3>
                            <h3 className="font-poppins text-sm sm:text-base md:text-lg">Setting up India’s largest
                                EV charging infrastructure</h3>

                        </div>
                    </div>

                </div>


            </div>

            <div className='sm:py-24 py-10 relative bg-gray-100'>

                <div className="absolute sm:-top-24 -top-10">
                    <SlidingElement direction="left">
                        <img className="sm:w-[170px] w-[80px]" src="/images/decorator.png" />
                    </SlidingElement>
                </div>


                <div className="flex sm:flex-row flex-col justify-center sm:gap-16 gap-4 sm:px-4 px-4">
                    <div className="sm:w-[400px] h-fit w-full bg-light-blue sm:rounded-[52px] rounded-[60px]">
                        <img className="sm:w-[400px] w-full" src="/images/ceo.webp" />
                        <div className="text-center sm:py-8 py-2">
                            <h3 className="font-virtue tracking-tighter text-white text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">Amit Kumar</h3>
                            <p className="font-poppins sm:text-2xl text-sm">Chief Executive Officer</p>
                        </div>
                    </div>

                    <div className="sm:w-[780px]">
                        <h3 className="font-virtue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl tracking-tighter text-blue ">
                            A Message From Our CEO
                        </h3>
                        <SlidingElement direction="right">

                            <p className="font-poppins text-sm sm:text-base md:text-lg sm:mt-8 mt-4">
                                At Let's EV, our vision is clear: to lead the transformation of India's mobility landscape by making electric vehicles accessible to all. We're committed to driving the nation’s shift towards sustainable transportation through innovative leasing solutions that are both affordable and transformative. Our focus isn't just on providing EV leasing solutions —it's on delivering an exceptional customer experience that sets a new standard in the industry.

                            </p>
                            <p className="font-poppins text-sm sm:text-base md:text-lg sm:mt-8 mt-4">
                                We deeply appreciate the trust our partners and customers have placed in us. Together, we’re not just adopting EVs—we’re pioneering a cleaner, greener future for our nation. We look forward to welcoming new partners on this journey as we continue to empower individuals and businesses to embrace the future of mobility.
                            </p>

                            <p className="font-poppins text-sm sm:text-base md:text-lg sm:mt-8 mt-4">
                                Join us in revolutionizing how India drives / moves.
                            </p>

                        </SlidingElement>
                    </div>


                </div>
            </div>

            <div className="sm:pt-20 pt-8 sm:pb-40 pb-16 relative">
                <div className="sm:px-32 px-4 flex sm:justify-start justify-between gap-12 font-poppins text-sm sm:text-base md:text-lg">
                    <div onClick={() => setCurrentTab(0)} className={`${currentTab == 0 ? "font-poppinsBold border-b-[2px]" : ""}  border-black text-center pb-2 leading-3 cursor-pointer`}>Our Vision</div>
                    <div onClick={() => setCurrentTab(1)} className={`${currentTab == 1 ? "font-poppinsBold border-b-[2px]" : ""}  border-black text-center pb-2 leading-3 cursor-pointer`}>Our Mission</div>
                    <div onClick={() => setCurrentTab(2)} className={`${currentTab == 2 ? "font-poppinsBold border-b-[2px]" : ""}  border-black text-center pb-2 leading-3 cursor-pointer`}>Our Values</div>
                </div>

                <div className="sm:px-32 px-4 sm:flex justify-center sm:gap-16 gap-8 pt-8 relative items-center">
                    {currentTab == 0 &&


                        <div className="h-fit sm:w-[415px] sm:text-right text-center flex flex-col sm:items-end items-center">
                            <div className="bg-light-blue sm:h-[140px] sm:w-[140px] h-[80px] w-[80px] flex justify-center items-center rounded-full">
                                <img className="sm:w-[100px] w-[40px]" src="/images/vision-icon.png" />
                            </div>
                            <h3 className="font-virtue tracking-tighter text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-blue mt-4">
                                Our Vision
                            </h3>
                            <p className="font-poppins text-sm sm:text-base md:text-lg sm:mt-4 mt-2">
                                To make EV leasing the primary driver<br />
                                of EV mobility adoption
                            </p>
                        </div>}
                    {currentTab == 1 &&


                        <div className="h-fit sm:w-[415px] sm:text-right text-center flex flex-col sm:items-end items-center">
                            <div className="bg-light-blue sm:h-[140px] sm:w-[140px] h-[80px] w-[80px] flex justify-center items-center rounded-full">
                                <RocketLaunchIcon className="text-white sm:w-28 w-10 sm:h-28 h-10" />
                            </div>
                            <h3 className="font-virtue tracking-tighter text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-blue mt-4">
                                Our Mission
                            </h3>
                            <p className="font-poppins text-sm sm:text-base md:text-lg sm:mt-4 mt-2">
                                To make EVs accessible and affordable to all by <br />
                                providing innovative leasing solutions,<br />
                                exceptional customer service and value creation.
                            </p>
                        </div>}
                    {currentTab == 2 &&


                        <div className="h-fit sm:w-[415px] sm:text-right text-center flex flex-col sm:items-end items-center">
                            <div className="bg-light-blue sm:h-[140px] sm:w-[140px] h-[80px] w-[80px] flex justify-center items-center rounded-full">
                                <ScaleIcon className="text-white sm:w-28 w-10 sm:h-28 h-10" />
                            </div>
                            <h3 className="font-virtue tracking-tighter text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-blue mt-4">
                                Our Values
                            </h3>
                            <p className="font-poppins text-sm sm:text-base md:text-lg sm:mt-4 mt-2">
                                These are the core values<br /> that drive our company
                            </p>
                        </div>}
                    <div className="sm:w-[680px] sm:h-[453px] h-fit sm:rounded-[70px] rounded-[40px] sm:mt-0 mt-4 overflow-hidden card-shadow">
                        {<img className={currentTab === 0 ? `block` : `hidden`} src="/images/our-vision.webp" />}
                        {<img className={currentTab === 1 ? `block` : `hidden`} src="/images/our-mission.webp" />}
                        {<img className={currentTab === 2 ? `block` : `hidden`} src="/images/our-values.webp" />}

                    </div>
                </div>

                <div className="absolute sm:bottom-20 bottom-4 sm:w-[170px] sm:h-[554px]">
                    <SlidingElement direction="left">
                        <img className="sm:w-[170px] w-[80px]" src="/images/decorator.png" />
                    </SlidingElement>
                </div>


                {currentTab == 2 && <div className="flex flex-col items-center mt-10">
                    <div className="font-poppins gap-10 flex md:flex-row flex-col text-sm sm:text-base md:text-lg sm:mt-4 mt-2 px-4">
                        <div className="w-[300px]">
                            <div className="font-virtue text-xl pt-4 text-blue">Spirit Of Partnership</div>
                            <div>Our fabric of relationship with our clients, suppliers and employees is knit with key values of fairness, respect, culture of service and brotherhood.</div>
                        </div>


                        <div className="w-[300px]">
                            <div className="font-virtue text-xl pt-4 text-blue">Health & Safety</div>
                            <div>Gensol is actively engaged in promoting the highest standards of occupational safety at all its project sites, thus, vouching for a work culture that ensures guilt-free success.</div>
                        </div>

                        <div className="w-[300px]">
                            <div className="font-virtue text-xl pt-4 text-blue">Quality Focused</div>
                            <div>Quality means doing it right when no one is looking, we are inclined to believe. Our dedication towards our work has been an outcome of unwavering intentions, sincere efforts and adept execution.</div>
                        </div>




                    </div>

                    <div className="font-poppins gap-10 flex md:flex-row flex-col text-sm sm:text-base md:text-lg sm:mt-4 mt-2 px-4">
                        <div className="w-[300px]">
                            <div className="font-virtue text-xl pt-4 text-blue">Taking a 360 Degree View</div>
                            <div>Since strong customer-centricity is at the core of our business, we do not mind going that extra mile and burning the midnight oil to make our clients feel special.</div>
                        </div>


                        <div className="w-[300px]">
                            <div className="font-virtue text-xl pt-4 text-blue">Driven by Engineering</div>
                            <div>“Engineers don’t sit back and watch; they make things happen” are words of some wise men. Our unabating passion for engineering has helped us constantly provide services to clients that lead to optimisation of resources and quicker turnaround of investment.</div>
                        </div>

                        <div className="w-[300px]">
                            <div className="font-virtue text-xl pt-4 text-blue">Hands On</div>
                            <div>With an immutable penchant for engineering, we are not theorists but strong believers in getting hands soiled with actual action on ground, since it is through experience that we learn what not to do.</div>
                        </div>
                    </div>

                </div>}




            </div>

            <div className="sm:py-10 py-4 sm:px-0 px-1 relative bg-gray-100 text-center flex flex-col items-center">
                <h3 className="font-virtue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl tracking-tighter text-blue">
                    Leadership Team
                </h3>
                <div className="grid grid-cols-3 sm:gap-20 gap-8 gap-y-8 sm:mt-10 mt-4">
                    <a target="_blank" href="https://www.linkedin.com/in/anmoljaggi/">
                        <div className="flex flex-col items-center">
                            <div className="w-fit mb-4"><img src="/images/leader-1.png" width={200} /></div>
                            <div className="text-center">
                                <h3 className="font-poppinsBold text-sm sm:text-base md:text-lg">Anmol Jaggi</h3>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">Managing Director</p>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">Gensol Engineering Ltd.</p>
                            </div>
                        </div>
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/in/puneetjaggi">
                        <div className="flex flex-col items-center">
                            <div className="w-fit mb-4"><img src="/images/leader-2.png" width={200} /></div>
                            <div className="text-center">
                                <h3 className="font-poppinsBold text-sm sm:text-base md:text-lg">Puneet Jaggi</h3>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">Whole-time Director</p>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">Gensol Engineering Ltd.</p>
                            </div>
                        </div>
                    </a>

                    <a target="_blank" href="https://www.linkedin.com/in/amit-kumar-revenuegeneration">
                        <div className="flex flex-col items-center">
                            <div className="w-fit mb-4"><img src="/images/leader-3.png" width={200} /></div>
                            <div className="text-center">
                                <h3 className="font-poppinsBold text-sm sm:text-base md:text-lg">Amit Kumar</h3>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">Chief Executive Officer</p>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">Let’sEV (Gensol EV Lease Pvt. Ltd.)</p>
                            </div>
                        </div>
                    </a>

                    <a target="_blank" href="https://www.linkedin.com/in/sonal-pande/">
                        <div className="flex flex-col items-center">
                            <div className="w-fit mb-4"><img src="/images/leader-4.png" width={200} /></div>
                            <div className="text-center">
                                <h3 className="font-poppinsBold text-sm sm:text-base md:text-lg">Sonal Pande</h3>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">VP, Sales & Marketing</p>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">Let’sEV</p>
                            </div>
                        </div>
                    </a>

                    <a target="_blank" href="https://www.linkedin.com/in/vinodgoel/">
                        <div className="flex flex-col items-center">
                            <div className="w-fit mb-4"><img src="/images/leader-5.png" width={200} /></div>
                            <div className="text-center">
                                <h3 className="font-poppinsBold text-sm sm:text-base md:text-lg">Vinod Goel</h3>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">VP, Finance & Accounting</p>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">Let’sEV</p>
                            </div>
                        </div>
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/in/saran-bhatia-4667234">
                        <div className="flex flex-col items-center">
                            <div className="w-fit mb-4"><img src="/images/leader-6.png" width={200} /></div>
                            <div className="text-center">
                                <h3 className="font-poppinsBold text-sm sm:text-base md:text-lg">Saran Bhatia</h3>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">Sr. VP, Management</p>
                                <p className="font-poppins text-sm sm:text-base md:text-lg leading-6">Let’sEV</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div className="flex sm:flex-row flex-col justify-center sm:gap-16 gap-8 sm:py-20 py-8 sm:px-0 px-4 relative items-center">
                <div className="sm:w-[50%] w-fit bg-light-blue pb-5 sm:rounded-[70px] rounded-[40px]">
                    <img src="/images/solar-panel.webp" />
                </div>
                <SlidingElement direction="right">
                    <div>
                        <h3 className="font-virtue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl tracking-tighter text-blue ">
                            About Gensol<br />
                            Engineering<br />
                        </h3>
                        <p className="sm:w-[370px] font-poppins text-sm sm:text-base md:text-lg mt-6">
                            <span className="font-poppinsBold">Gensol Engineering Limited</span>, a listed entity NSE and BSE main boards, is a leading player in the renewable energy sector specializing in solar power engineering, procurement, and construction (EPC) services, along with electric mobility solutions. Established in 2012, it has a track record of expertise in design engineering, execution and scale in Solar EPC including turnkey and BOS. It has also diversified its offerings with solar tracker technology (with acquisition of Scorpius Trackers) and Battery Energy Storage Solutions (BESS). Gensol boasts an experienced and diverse team of over 500 professionals across EPC and its subsidiaries EV Manufacturing (Gensol EV), EV leasing (Let’sEV) and Scorpius Trackers.
                        </p>
                        <a target="_blank" href="https://www.gensol.in/">
                            <button className="bg-blue hover:bg-light-blue transition duration-300 text-white text-sm sm:text-base md:text-lg font-poppins mt-6 px-12 py-3 rounded-full">
                                Learn More
                            </button>
                        </a>
                    </div>
                </SlidingElement>

            </div>


        </main>

    </>
}