import SlidingElement from './utils/SlidingElement';
import Lifecycle from './utils/Lifecycle';
import React from 'react';
import Glider from 'react-glider';
import Slider from 'react-slick';
import 'glider-js/glider.min.css';

const items = [
  { name: 'Client moeving', img: '/images/client-1.png', link: 'https://moeving.com/' },
  { name: 'Client snapecabs', img: '/images/client-2.png', link: 'https://snapecabs.com/' },
  { name: 'Client oredaodisha', img: '/images/client-3.png', link: 'https://oredaodisha.com/' },
  { name: 'Client recindia', img: '/images/client-4.png', link: 'https://recindia.nic.in/' },
  { name: 'Client bluwheelz', img: '/images/client-5.png', link: 'https://bluwheelz.co.in/beta/' },
  { name: 'Client blu-smart', img: '/images/client-6.png', link: 'https://blu-smart.com/en-IN/' },
  { name: 'Client exponent energy', img: '/images/client-7.svg', link: 'https://www.exponent.energy/' },
  { name: 'Client omegaseikimobility', img: '/images/client-8.png', link: 'https://omegaseikimobility.com/' },
  { name: 'Client team magenta', img: '/images/client-9.webp', link: 'https://www.teammagenta.com/' },
  { name: 'Client log9materials', img: '/images/client-10.jpeg', link: 'https://www.log9materials.com/' },


  // Add more items as needed
];

const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1500,
  autoplaySpeed: 1500,
  cssEase: "linear",
};

export default function Home() {

  return (
    <main className='overflow-hidden'>
      <div className="relative 2xl:h-[760px] xl:h-[634px] lg:h-[506px] md:h-[380px] sm:h-[316px] overflow-hidden">
        <img
          src="/images/home-mobile-hero.webp"
          className="block sm:hidden w-full"
          alt="Mobile Hero"
          style={{ width: "100vw" }}
        />
        {/* Desktop image */}
        <SlidingElement direction="left">

          <img
            src="/images/home-hero.webp"
            className="hidden sm:block w-full"
            alt="Desktop Hero"
            style={{ width: "100vw" }}
          />
        </SlidingElement>


        {/* <div className='absolute flex justify-center sm:bottom-[40px] bottom-[240px] w-full'>
          <ChevronDownIcon className='animate-float sm:w-[100px] w-[40px]' color='#cccbcb' />
        </div> */}
        <div className="sm:absolute lg:top-[30%] top-[25%] lg:right-[10%] right-[4%] h-fit sm:px-0 px-4 sm:mt-0 mt-10">
          <SlidingElement direction="right">
            <h3 className="font-virtue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl tracking-tighter text-blue">
              One Planet.<br />
              One Solution.<br />
              Let’sEV.<br />
            </h3>

            <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-poppins mt-4">
              The Fastest Growing EV Focused<br />
              Leasing Company is Here to<br />
              Transform Mobility
            </p>

            <a href="/about-us#who-we-are">
              <button className="bg-blue hover:bg-light-blue transition duration-300 text-white text-sm sm:text-base md:text-lg font-poppins mt-4 px-12 py-3 rounded-full">
                Learn More
              </button>
            </a>
          </SlidingElement>

        </div>

      </div>

      <div className="flex sm:flex-row flex-col justify-center gap-4 sm:gap-16  xl:pt-32 sm:pt-20 pt-10 relative sm:pb-72 pb-20 items-center">

        <div className="sm:w-[45%] w-[90%] 2xl:w-fit bg-light-blue pb-5 sm:rounded-[70px] rounded-[30px]">
          <img src="/images/tunnel-car.webp" />
        </div>
        <SlidingElement direction="right">
          <div className='w-[100vw] sm:w-fit text-left px-6 sm:px-0'>
            <h3 className="font-virtue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl tracking-tighter text-blue ">
              Join Us in<br />
              Paving A New<br />
              Future of Mobility
            </h3>
            <p className="w-fit sm:w-[370px] font-poppins text-sm sm:text-base md:text-lg mt-3">
              India’s fastest growing EV focused Leasing Company, with an aim to be India’s largest. Providing a comprehensive EV Leasing and Fleet Management solution. Delivering substantial cost savings and reduced administrative hassles for our customers. Promoting a sustainable and environmentally conscious mobility.
            </p>
            <a href='/solutions'>
              <button className="bg-blue hover:bg-light-blue transition duration-300 text-white text-sm sm:text-base md:text-lg font-poppins mt-3 px-12 py-3 rounded-full">
                Learn More
              </button>
            </a>
          </div>
        </SlidingElement>


        <div className="absolute sm:bottom-20 bottom-5 left-0">
          <SlidingElement direction="left">
            <img className='sm:w-[170px] w-[50px]' src="/images/decorator.png" />
          </SlidingElement>
        </div>
      </div>

      <div className='flex justify-center full-glider' >
        <div className='w-full'>

          <Glider
            draggable={false}
            scrollLock
            hasArrows
            hasDots
            slidesToShow={1}
            slidesToScroll={1}
          >
            <div className="relative">
              <img width={"70%"} className='sm:h-auto h-[350px]' src="/images/our-offering.webp" />

              <div className='md:min-h-[350px] absolute bg-white md:right-[15%] right-0 md:top-10 sm:top-10 top-4  border-light-blue border-2 card-shadow border-solid flex flex-col items-center px:gap-6 gap-2 md:px-10 sm:px-5 px-1 sm:py-8 py-3 rounded-2xl'>
                <h3 className='font-poppinsBold text-light-blue text-2xl'>OUR OFFERING</h3>
                <img width={50} src="/images/money-dark-icon.png" />
                <div>
                  <h3 className='font-poppinsBold text-blue text-base sm:text-lg md:text-xl lg:text-2xl text-center'>Budgeted<br />
                    Lease Cost</h3>
                  <p className='font-poppins text-blue md:w-[300px] w-80 text-center'>Capped expenses for the entire life cycle of the EV to assure that leasing delivers the maximum for your organization while meeting your specific businesses needs</p>
                </div>
                <a href="/solutions#our-offerings-0">

                  <button className="bg-light-blue hover:bg-blue transition duration-300 text-white text-sm font-poppins px-7 py-2 rounded-full">
                    Learn More
                  </button>

                </a>

              </div>
            </div>
            <div className="relative">
              <img width={"70%"} className='sm:h-auto h-[350px]' src="/images/our-offering-1.webp" />

              <div className='md:min-h-[350px] absolute bg-white md:right-[15%] right-0 md:top-10 sm:top-10 top-4  border-light-blue border-2 card-shadow border-solid flex flex-col items-center px:gap-6 gap-2 md:px-10 sm:px-5 px-8 sm:py-8 py-3 rounded-2xl'>
                <h3 className='font-poppinsBold text-light-blue text-2xl'>OUR OFFERING</h3>
                <img width={50} src="/images/network-icon.png" />
                <div>
                  <h3 className='font-poppinsBold text-blue text-base sm:text-lg md:text-xl lg:text-2xl text-center'>One-stop<br />
                    Solution</h3>
                  <p className='font-poppins text-blue md:w-[300px] w-48 text-center'>One-stop EV leasing solution for businesses and individuals
                  </p>
                </div>
                <a href="/solutions#our-offerings-1">

                  <button className="bg-light-blue hover:bg-blue transition duration-300 text-white text-sm font-poppins px-7 py-2 rounded-full">
                    Learn More
                  </button>

                </a>

              </div>
            </div>
            <div className="relative">
              <img width={"70%"} className='sm:h-auto h-[350px]' src="/images/our-offering-2.webp" />

              <div className='md:min-h-[350px] absolute bg-white md:right-[15%] right-0 md:top-10 sm:top-10 top-4  border-light-blue border-2 card-shadow border-solid flex flex-col items-center px:gap-6 gap-2 md:px-10 sm:px-5 px-8 sm:py-8 py-3 rounded-2xl'>
                <h3 className='font-poppinsBold text-light-blue text-2xl'>OUR OFFERING</h3>
                <img width={50} src="/images/graph-icon.png" />
                <div>
                  <h3 className='font-poppinsBold text-blue text-base sm:text-lg md:text-xl lg:text-2xl text-center'>Accelerating the<br />
                    EV Adoption</h3>
                  <p className='font-poppins text-blue md:w-[300px] w-48 text-center'>Make EVs accessible and<br /> affordable for all
                  </p>
                </div>
                <a href="/solutions#our-offerings-2">

                  <button className="bg-light-blue hover:bg-blue transition duration-300 text-white text-sm font-poppins px-7 py-2 rounded-full">
                    Learn More
                  </button>

                </a>

              </div>
            </div>
            <div className="relative">
              <img width={"70%"} className='sm:h-auto h-[350px]' src="/images/our-offering-3.webp" />

              <div className='md:min-h-[350px] absolute bg-white md:right-[15%] right-0 md:top-10 sm:top-10 top-4  border-light-blue border-2 card-shadow border-solid flex flex-col items-center px:gap-6 gap-2 md:px-10 sm:px-5 px-8 sm:py-8 py-3 rounded-2xl'>
                <h3 className='font-poppinsBold text-light-blue text-2xl'>OUR OFFERING</h3>
                <img width={50} src="/images/jigsaw-icon.png" />
                <div>
                  <h3 className='font-poppinsBold text-blue text-base sm:text-lg md:text-xl lg:text-2xl text-center'>Flexible<br />
                    Leasing Solution</h3>
                  <p className='font-poppins text-blue md:w-[300px] w-48 text-center'>Customised solutions for various use cases
                  </p>
                </div>
                <a href="/solutions#our-offerings-3">

                  <button className="bg-light-blue hover:bg-blue transition duration-300 text-white text-sm font-poppins px-7 py-2 rounded-full">
                    Learn More
                  </button>

                </a>

              </div>
            </div>
            <div className="relative">
              <img width={"70%"} className='sm:h-auto h-[350px]' src="/images/our-offering-4.webp" />

              <div className='md:min-h-[350px] absolute bg-white md:right-[15%] right-0 md:top-10 sm:top-10 top-4  border-light-blue border-2 card-shadow border-solid flex flex-col items-center px:gap-6 gap-2 md:px-10 sm:px-5 px-8 sm:py-8 py-3 rounded-2xl'>
                <h3 className='font-poppinsBold text-light-blue text-2xl'>OUR OFFERING</h3>
                <img width={50} src="/images/hand-shake-icon.png" />
                <div>
                  <h3 className='font-poppinsBold text-blue text-base sm:text-lg md:text-xl lg:text-2xl text-center'>Leasing Experts</h3>
                  <p className='font-poppins text-blue md:w-[300px] w-48 text-center'>Best talent in the industry to innovate and customise leasing solutions
                  </p>
                </div>
                <a href="/solutions#our-offerings-4">

                  <button className="bg-light-blue hover:bg-blue transition duration-300 text-white text-sm font-poppins px-7 py-2 rounded-full">
                    Learn More
                  </button>

                </a>

              </div>
            </div>
            <div className="relative">
              <img width={"70%"} className='sm:h-auto h-[350px]' src="/images/our-offering-5.webp" />

              <div className='md:min-h-[350px] absolute bg-white md:right-[15%] right-0 md:top-10 sm:top-10 top-4  border-light-blue border-2 card-shadow border-solid flex flex-col items-center px:gap-6 gap-2 md:px-10 sm:px-5 px-8 sm:py-8 py-3 rounded-2xl'>
                <h3 className='font-poppinsBold text-light-blue text-2xl'>OUR OFFERING</h3>
                <img width={50} src="/images/gear-icon.png" />
                <div>
                  <h3 className='font-poppinsBold text-blue text-base sm:text-lg md:text-xl lg:text-2xl text-center'>Experience &<br /> Infrastructure</h3>
                  <p className='font-poppins text-blue md:w-[300px] w-48 text-center'>Experience of life cycle management of over 225 Million Kms with 7000+ BluSmart fleet
                  </p>
                </div>
                <a href="/solutions#our-offerings-5">

                  <button className="bg-light-blue hover:bg-blue transition duration-300 text-white text-sm font-poppins px-7 py-2 rounded-full">
                    Learn More
                  </button>

                </a>

              </div>
            </div>
          </Glider>
        </div>
      </div>

      <Lifecycle />

      <div className='py-10 relative bg-gray-100'>
        <h3 className='text-center font-virtue text-blue text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl sm:mb-16 mb-8 tracking-tighter'>
          Why Leasing is Better Than Buying
        </h3>


        <div className='flex justify-center'>
          <div className='w-[90%]'>

          </div>

        </div>

        <div className='flex justify-center gap-10 md:flex-row flex-col px-10'>
          <div className='flex flex-col items-center xl:w-[290px] lg:w-[230px] w-full'>
            <div className="flex flex-col sm:gap-4 gap-2">
              <div className='md:h-32 h-16 md:w-32 w-16 rounded-full flex justify-center items-center bg-blue'>
                <img className='md:w-[80px] w-[40px]' src="/images/why-lease-1.svg" />
              </div>
              <h3 className='font-virtue text-blue  text-base sm:text-lg md:text-xl lg:text-2xl tracking-tighter'>
                Zero Down<br />Payment
              </h3>
              <div className="xl:w-[280px] lg:w-[230px] w-full font-poppins text-xs md:text-base">
                <ul>
                  <li>
                    <b className="font-gothamBold text-light-blue">Leasing</b> Deploy your electric vehicle fleet without hefty down payments or
                    bank loans
                  </li>
                  <li className='md:hidden block'>
                    <b className="font-gothamBold text-light-blue">Buying</b> Face higher interest rates on auto loans and invest heavy down
                    payments to acquire an electric vehicle fleet
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center xl:w-[290px] lg:w-[230px] w-full'>
            <div className="flex flex-col sm:gap-4 gap-2">
              <div className='md:h-32 h-16 md:w-32 w-16 rounded-full flex justify-center items-center bg-blue'>
                <img className='md:w-[80px] w-[40px]' src="/images/why-lease-2.svg" />
              </div>
              <h3 className='font-virtue text-blue  text-base sm:text-lg md:text-xl lg:text-2xl tracking-tighter'>
                Latest<br /> technology
              </h3>
              <div className="xl:w-[280px] lg:w-[230px] w-full font-poppins text-xs md:text-base">
                <ul>
                  <li>
                    <b className="font-gothamBold text-light-blue">Leasing</b> Upgrade to the newest model every few years and experience
                    cutting edge features
                  </li>
                  <li className='md:hidden block'>
                    <b className="font-gothamBold text-light-blue">Buying</b> To cope with vehicle value depreciation and battery degradation over
                    the years without upgrading to a newer model
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center xl:w-[290px] lg:w-[230px] w-full'>
            <div className="flex flex-col sm:gap-4 gap-2">
              <div className='md:h-32 h-16 md:w-32 w-16 rounded-full flex justify-center items-center bg-blue'>
                <img className='md:w-[80px] w-[40px]' src="/images/why-lease-3.svg" />
              </div>
              <h3 className='font-virtue text-blue  text-base sm:text-lg md:text-xl lg:text-2xl tracking-tighter'>
                Charge<br /> stress-free
              </h3>
              <div className="xl:w-[280px] lg:w-[230px] w-full font-poppins text-xs md:text-base">
                <ul>
                  <li>
                    <b className="font-gothamBold text-light-blue">Leasing</b> Get customized charging infrastructure to fit your business needs
                    and benefit from seamless operations
                  </li>
                  <li className='md:hidden block'>
                    <b className="font-gothamBold text-light-blue">Buying</b> Accessing fast charging infrastructure as needed is challenging,
                    alongside complicating operations and reliant on large initial costs for setup
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center xl:w-[290px] lg:w-[230px] w-full'>
            <div className="flex flex-col sm:gap-4 gap-2">
              <div className='md:h-32 h-16 md:w-32 w-16 rounded-full flex justify-center items-center bg-blue'>
                <img className='md:w-[80px] w-[40px]' src="/images/why-lease-4.svg" />
              </div>
              <h3 className='font-virtue text-blue  text-base sm:text-lg md:text-xl lg:text-2xl tracking-tighter'>
                Peace of mind<br /> and convenience
              </h3>
              <div className="xl:w-[280px] lg:w-[230px] w-full font-poppins text-xs md:text-base">
                <ul>
                  <li>
                    <b className="font-gothamBold text-light-blue">Leasing</b> End-to-end management, services – maintenance, resale,
                    roadside assistance and insurance, so – you don’t have to
                  </li>
                  <li className='md:hidden block'>
                    <b className="font-gothamBold text-light-blue">Buying</b> Owner must confront higher total cost of ownership (TCO) and a
                    lasting commitment of fleet ownership
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className='md:block hidden'>
          <div className='flex justify-center gap-10 md:flex-row flex-col px-10 mt-4'>
            <div className='flex flex-col items-center xl:w-[290px] lg:w-[230px] w-full'>
              <div className="flex flex-col sm:gap-4 gap-2">

                <div className="xl:w-[280px] lg:w-[230px] w-full font-poppins text-xs md:text-base">
                  <ul>
                    <li>
                      <b className="font-gothamBold text-light-blue">Buying</b> Face higher interest rates on auto loans and invest heavy down
                      payments to acquire an electric vehicle fleet
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='flex flex-col items-center xl:w-[290px] lg:w-[230px] w-full'>
              <div className="flex flex-col sm:gap-4 gap-2">

                <div className="xl:w-[280px] lg:w-[230px] w-full font-poppins text-xs md:text-base">
                  <ul>
                    <li>
                      <b className="font-gothamBold text-light-blue">Buying</b> To cope with vehicle value depreciation and battery degradation over
                      the years without upgrading to a newer model
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='flex flex-col items-center xl:w-[290px] lg:w-[230px] w-full'>
              <div className="flex flex-col sm:gap-4 gap-2">

                <div className="xl:w-[280px] lg:w-[230px] w-full font-poppins text-xs md:text-base">
                  <ul>
                    <li>
                      <b className="font-gothamBold text-light-blue">Buying</b> Accessing fast charging infrastructure as needed is challenging,
                      alongside complicating operations and reliant on large initial costs for setup
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='flex flex-col items-center xl:w-[290px] lg:w-[230px] w-full'>
              <div className="flex flex-col sm:gap-4 gap-2">

                <div className="xl:w-[280px] lg:w-[230px] w-full font-poppins text-xs md:text-base">
                  <ul>
                    <li>
                      <b className="font-gothamBold text-light-blue">Buying</b> Owner must confront higher total cost of ownership (TCO) and a
                      lasting commitment of fleet ownership
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="text-center sm:py-10 py-4 relative bg-white-100">
        <h3 className="font-virtue text-blue text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl mb-4 tracking-tighter">
          Clients on Board
        </h3>
        <Slider {...settings}>
          {items.map((item, index) => (
            <div key={index} className="inline-block">
              <a target="_blank" rel="noopener noreferrer" href={item.link}>
                <img className={`sm:w-[144px] w-[36px]`} src={item.img} alt={item.name} />
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className='text-center py-10 relative bg-gray-100'>
        <h3 className='font-virtue text-blue text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-16 tracking-tighter'>
          Testimonials
        </h3>

        <div className='flex justify-center'>
          <div className='sm:w-[820px] w-[80vw] font-poppins text-xl relative'>
            <div className='absolute left-0 right-0 m-auto w-fit -top-10'>
              <img src="/images/quotes-icon.png" width={96} />
            </div>
            <Glider
              draggable={false}
              scrollLock
              hasArrows
              hasDots={false}
              slidesToShow={1}
              slidesToScroll={1}
            >
              <div className="flex flex-col items-center text-center px-8">
                <p className="text-base">
                  My experience with Gensol EV Lease Private Limited has been extremely positive.  I have had the pleasure to interact with people from nearly every department, and their entire team has been absolutely professional and wonderful to deal with.  I have been very impressed with their service and responsiveness.
                </p>
                {/* <h3 className='font-poppinsBold sm:text-2xl text-sm mt-6'>Team Exponent Energy</h3> */}
                <p className='sm:text-base text-xs text-[#626366] mt-6'>Team Exponent Energy</p>
              </div>
              <div className="flex flex-col items-center text-center px-8">
                <p className="text-base">
                  Let’s EV in a short span of time became one of our most preferred EV-financing partners. The team’s swift approach and tailor-made solutions has allowed Omega Seiki Mobility to close multiple deals quickly, this was possible due to the team's focus on building relationships. Their EV-only approach aligns much better with OSM’s philosophy. We look forward to work closely with the teams at Gensol to reach greater heights together!
                </p>
                {/* <h3 className='font-poppinsBold sm:text-2xl text-sm mt-6'>Vivek martin</h3> */}
                <p className='sm:text-base text-xs text-[#626366] mt-6'>Team Omega Seiki Mobility</p>
              </div>
              <div className="flex flex-col items-center text-center px-8">
                <p className="text-base">
                  At SnapE Cabs, we take pride in our commitment to providing sustainable transportation solutions, and Gensol Leasing is a key partner in making this vision a reality. Their seamless leasing process and unwavering support have allowed us to grow our electric vehicle fleet with ease.We value their professionalism, reliability, and dedication to helping us achieve our goals. Together, we’re driving toward a greener, cleaner future
                </p>
                {/* <h3 className='font-poppinsBold sm:text-2xl text-sm mt-6'>Vivek martin</h3> */}
                <p className='sm:text-base text-xs text-[#626366] mt-6'>Team SnapE Cabs</p>
              </div>
              <div className="flex flex-col items-center text-center px-8">
                <p className="text-base">
                  Gensol and its group companies are a harbinger of sustainable mobility in India. Gensol's positive attitude towards providing finance for Electric Vehicle deployment is a good tango with Magenta's mission of decarbonising Logistics. Gensol has carved out a niche in a new segment and I am sure they will continue to play the role of the key EV ecosystem player in the days to come
                </p>
                {/* <h3 className='font-poppinsBold sm:text-2xl text-sm mt-6'>Vivek martin</h3> */}
                <p className='sm:text-base text-xs text-[#626366] mt-6'>Team Magenta</p>
              </div>
              <div className="flex flex-col items-center text-center px-8">
                <p className="text-base">
                  We are thrilled to partner with Gensol for EV Leasing - a first for Gensol as an asset category. This reinforces Log9’s superior battery tech building trust. The partnership will extend to various other asset categories powered by log9 batteries backed by Gensol’s great pedigree in leasing lead by Amit Kumar , who we admire. Shout out to Sonal for cementing this partnership.
                  <br />
                  Together, we'll make electric vehicles more accessible, reliable, and efficient. This partnership moves us closer to a cleaner and more sustainable future for India.
                </p>
                <p className='sm:text-base text-xs text-[#626366] mt-6'>Team Log9 Materials</p>
              </div>
            </Glider>
          </div>
        </div>
      </div>




    </main>
  );
}
